import { useAccount, useReadContract, useWriteContract } from "wagmi";
import { useMemo } from "react";
import { formatEther } from "viem";

import gauabi from "../ABI/RealGauAbi.json";
import gaucukAbi from "../ABI/GAUABI.json";
import { enqueueSnackbar } from "notistack";

const GAU_ADDRESS = "0xCa8EBfB8e1460Aaac7c272CB9053B3D42412AAc2";
const GAUCUK_ADDRESS = "0x13Cc22357Ec72c4dea99c5076e36b621b4c1fC8D";
const MAIN_GAU_WALLET = "0x852771B31fC931EF6602169376f5A559f5279595";

export const useWeb3Functions = () => {
  const { address, isConnected } = useAccount();
  function replaceZeros(amount, string) {
    if (amount.toString().includes(".")) {
      const [whole, decimal = ""] = amount.toString().split(".");
      if (decimal.length > 0)
        return `${whole}${
          string.substring(0, 0) + decimal + string.substring(decimal.length)
        }`;
    }
    console.log("if u r read this, this is a book");
    return amount + string;
  }
  const getMyGauBalance = useReadContract({
    abi: gauabi,
    address: GAU_ADDRESS,
    functionName: "balanceOf",
    args: [address],
  });
  const myGauBalance = useMemo(() => {
    console.log(
      isConnected,
      getMyGauBalance,
      getMyGauBalance.data,
      getMyGauBalance.error,
      getMyGauBalance.failureReason
    );
    if (isConnected && getMyGauBalance.data) {
      return Number(formatEther(getMyGauBalance.data)).toFixed(2);
    } else return "0";
  }, [isConnected, getMyGauBalance]);

  function useSendTransaction() {
    const { writeContractAsync } = useWriteContract();
    function sendTransaction(
      summaryState,
      successCb = () => {},
      errorCb = () => {}
    ) {
      if (!isConnected) {
        return enqueueSnackbar(`Wallet not connected`, {
          variant: "error",
        });
      }
      let summaryStateString = replaceZeros(summaryState, "000000000000000000");
      // summaryState?.toString() + "000000000000000000";
      console.log("isconn", isConnected, summaryStateString);
      writeContractAsync({
        abi: gauabi,
        address: GAU_ADDRESS,
        functionName: "transfer",
        args: [MAIN_GAU_WALLET, summaryStateString],
      })
        .then((success) => {
          successCb?.();
          console.log("success", success);
        })
        .catch((err) => {
          if (err.shortMessage) {
            // enqueueSnackbar(`${err.shortMessage}`, {
            //   variant: "error",
            // });
          }
          console.log("errorr", err, err.shortMessage);
          enqueueSnackbar(
            err.shortMessage || "Something went wrong. Try again later.",
            {
              variant: "error",
            }
          );
          errorCb?.();
        });
    }

    return { sendTransaction };
  }
  return {
    myGauBalance,
    useSendTransaction,
    address,
    isConnected,
  };
};
